<template>
    <div class="main-page">
        <section class="page-section mb-3" >
            <div class="container">
                <div class="grid ">
                    <div class="col-12 comp-grid" >
                        <div class="">
                            <div class=" text-2xl font-bold" >
                                Selamat Datang,
                                <div class="text-sm text-primary"> di Platform Kiosery oleh PT. Nusantara Bina Artha </div>
                            </div>
                            <hr class="my-3" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="page-section mb-3" >
            <div class="container">
                <div class="grid ">
                    <div class="col-12 comp-grid" >
                        <div class="">
                            <div class="">
                                <img class="img-fluid" src="images/cover2.png"   style="max-width:100%;width:100vw;" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="page-section mb-3" >
            <div class="container">
                <div class="grid ">
                    <div class="col-12 comp-grid" >
                        <div class="">
                            <div class="" >
                                <div class="flex align-items-center ">
                                    <div class="mr-2">
                                        <Avatar size="large" class="primary" icon="pi pi-bookmark-fill" />
                                        </div>
                                        <div>
                                            <div class="text-2xl font-bold">Tentang Kiosery</div>
                                        </div>
                                    </div>
                                </div>
                                <hr class="my-3" />
                            </div>
                        </div>
                        <div class="col-12 comp-grid" >
                            <div class="">
                                <div class="">
                                    <div>
                                        Kami adalah fasilitator rantai pasok berbasis digital yang paling bermanfaat dan terpercaya untuk masyarakat Indonesia.
                                        <br><br>
                                        <b>Misi Kami :</b>
                                        <br><br>
                                        o Memberikan para mitra fasilitas <i>digital platform</i> untuk saling bertransaksi dan akses terhadap layanan keuangan.
                                        <br><br>
                                        o Memberikan para mitra nilai tambah agar usahanya dapat tumbuh dan berkembang. 
                                        <br><br>
                                        o Menjaga keberlanjutan manfaat bagi semua pemangku kepentingan dan masyarakat Indonesia.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="page-section mb-3" >
                <div class="container">
                    <div class="grid ">
                        <div class="col-12 comp-grid" >
                            <div class="">
                                <div class="">
                                    <div>
                                        <KontakListIndexPage ref="kontakListPage"  :limit="10" :show-header="true" :show-breadcrumbs="true" :show-footer="true" :paginate="true" v-if="pageReady">
                                        </KontakListIndexPage>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="page-section mb-3" >
                <div class="container">
                    <div class="grid justify-content-center ">
                        <div class="col-12 comp-grid" >
                            <div class="">
                                <div :class="{ 'card': !isSubPage }" class="">
                                    <Button label="Login" icon="" @click="Modal529 = true" class="" />
                                    <Dialog v-model:visible="Modal529" :dismissableMask="true" :breakpoints="{'960px': '50vw', '640px': '95vw'}" modal style="width:750px" >
                                        <template #header>
                                            <div class="text-2xl">Authorized Access Only</div>
                                        </template>
                                        <div class="">
                                            <div >
                                                <form ref="observer"  tag="form" @submit.prevent="startLogin()">
                                                    <div class="mb-2 p-input-icon-left w-full">
                                                        <i class="pi pi-user"></i>
                                                        <InputText label="Username" placeholder="Username" class="w-full" v-model.trim="formData.username"  required="required" type="text" />
                                                    </div>
                                                    <div class="mb-2 p-input-icon-left w-full">
                                                        <i class="pi pi-lock"></i>
                                                        <Password label="Password" inputClass="w-full" :feedback="false" toggleMask class="w-full" placeholder="Password" required="required" v-model="formData.password" />
                                                    </div>
                                                    <div class="flex justify-content-between align-items-center my-2">
                                                        <div class="flex align-items-center">
                                                            <Checkbox class="mr-2" id="rememberme" :binary="true" v-model="rememberUser" />
                                                            <label class="text-sm text-500" for="rememberme">Remember Me</label>
                                                        </div>
                                                    </div>
                                                    <Message v-if="loginErrorMsg" severity="error" :closable="true" @close="loginErrorMsg=null">
                                                    {{ loginErrorMsg.toString() }}
                                                    </Message>
                                                    <div class="text-center">
                                                        <Button label="Login"  :loading="loading" icon="pi pi-lock-open" class="w-full"  type="submit"> 
                                                        </Button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </Dialog>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
</template>
<script setup>
	import {  ref, reactive } from 'vue';
	import { useApp } from "@/composables/app";
	import { useStore } from 'vuex'
	import { useRoute, useRouter } from 'vue-router';
	import KontakListIndexPage from "../kontak/list_index.vue";
	const props = defineProps({
		pageName: {
			type: String,
			default: 'index',
		},
		routeName: {
			type: String,
			default: 'index',
		},
		isSubPage: {
			type : Boolean,
			default : false,
		},
	});
	const route = useRoute();
	const router = useRouter();
	const store = useStore();
	const app = useApp();
	const formData = reactive({
		username: '',
		password:'',
	});
	const loading = ref(false);
	const pageReady = ref(true);
	const loginErrorMsg = ref('');
	const rememberUser = ref(false);
	const Modal529 = ref(false);
	async function startLogin (){
		try{
			loading.value = true;
			loginErrorMsg.value = '';
			let url = "/auth/login";
			let payload = {
				formData,
				rememberUser:rememberUser.value,
				url
			};
			const loginData = await store.dispatch('auth/login', payload);
			loading.value = false;
			if (loginData.token) {
				let nextUrl = route.query.nexturl || '/home'
				router.go(nextUrl);
			}
			else{
				router.push(loginData.nextpage);
			}
		}
		catch(error){
			loading.value = false;
			loginErrorMsg.value = error.request?.response || "Unable to establish connection...";
		}
	}
</script>
<style></style>
